import React, { useState, useEffect } from 'react';
import axios from 'axios';


import { AiFillLike, AiFillMessage } from "react-icons/ai";
import { FaShoppingCart } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import InsideHeader from './InsideHeader';
import { MdEmail } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { IoMdPerson } from "react-icons/io";
import { FaHouseUser } from "react-icons/fa";
import AnotherHeader from './AnotherHeader';
import { BsWhatsapp } from "react-icons/bs";
import {IoMdCall } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { FaHouse } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";


function RentHouse() {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [currentProductOwner, setCurrentProductOwner] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [newProduct, setNewProduct] = useState({
    name: '',
    price: '',
    description: '',
    status: ''
  });
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const res = await axios.get(`https://mybackend-new.onrender.com/houses?search=${searchTerm}`);
      setProducts(res.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMessageClick = (ownerId) => {
    setCurrentProductOwner(ownerId);
    setShowMessageModal(true);
  };

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://mybackend-new.onrender.com/products/messages`, {
        ownerId: currentProductOwner,
        content: messageContent
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setShowMessageModal(false);
      setMessageContent('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleOrderClick = async (productId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`https://mybackend-new.onrender.com/products/orders`, {
        productId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert('Order placed successfully!');
      }
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };





  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);
  };

  return (
    <div>
      <AnotherHeader/>
      <InsideHeader isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className="relative min-h-screen flex">
        <div className="relative  flex">
       

          {loading ? (<div></div>
          ) : (
          <div className="flex-1 p-0 rounded mt-20">
            <div className=" p-1 bg-white">
            <div className="flex items-center justify-center mb-4">
                <FaHouse className="text-2xl mr-2" />
                <h2 className="text-2xl font-semibold text-center">Accommodation</h2>
              </div>
              <div className="mb-4 relative">
              <FaSearch className="absolute mr-auto right-3 top-1/2 transform -translate-y-1/2 text-gray-500" size={20} />
                    
                <input
                  type="text"
                  placeholder="Search by name, description, or owner"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className="p-2 border w-full border-gray-300 rounded-md"
                />
              </div>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-1">
                {filteredProducts.map(product => (
                  <div key={product.id} className="border p-1 rounded-md shadow">
                    <img src={`${product.picture}`} alt={product.name}   onClick={() => toggleImage(`${product.picture}`)}   className="w-full h-44 object-cover mb-2 rounded-md border" />
                   
                   
                   <div className='pl-1 pr-1' >
                    <h2 className="text-md font-bold">{product.name}</h2>
                    <div className="text-gray-700 text-sm max-h-24 overflow-hidden">
                      {expandedDescriptions[product.id] || product.description.length <= 10
                        ? product.description
                        : `${product.description.substring(0, 8)}...`}
                      {product.description.length > 5 && (
                        <span
                          onClick={() => toggleDescription(product.id)}
                          className="text-blue-500 text-xs cursor-pointer"
                        >
                          {expandedDescriptions[product.id] ? ' Show Less' : 'Read More'}
                        </span>
                      )}
                    </div>
                    <p className="text-sm font-semibold">Price: MK{product.price}</p>
                    <p className='text-xs'>Status: {product.status}</p>
                   
                
                    <p className='text-xs'>Location: {product.location}</p>
                
                    <div className="flex items-center mt-2">
                      <button
                       onClick={() => togglePersonModal(product)}
                        className="text-gray-800 text-xs font-semibold mr p-0 border-none bg-gray-300 rounded cursor-pointer"
                      >
                       <h6 className='p-1' >Book Now</h6>
                      </button>
                   
                     
                    </div>
           
                  </div>
                </div>  
                ))}
              </div>
            </div>
          </div>
          )}
        </div>
      
        {showPersonModal && currentProduct && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8  rounded-3xl shadow-lg z-60">
            <div className="flex items-center justify-center mb-4">
             <IoMdPerson  className="text-2xl mr-2" />
              <h2 className="text-2xl font-bold text-center">Person Information</h2>
               </div>
              <h5  className='text-xs pb-2 font-semibold text-center' >Use the information provided below to contact the owner to book the place</h5>
              <p className='text-2xs'>Owner   : {currentProduct.owner_username}</p>
              <p className='text-2xs'>Phone   : {currentProduct.phoneNumber}</p>
              <p className='text-2xs'>Location: {currentProduct.location}</p>
              <p className='text-2xs'>Email   : {currentProduct.email}</p>
             
              <div className="flex space-x-4 mt-2">
              <button
                onClick={togglePersonModal}
                className="p-2 bg-red-800 text-white rounded-xl flex items-center"
                >
                  <MdCancel className="mr-1" />
                Close
              </button>






               

                               
<div className="flex items-center space-x-2 bg-gray-600 text-white p-2 rounded-xl">
  <span>Connect</span>

  <button className="p-1 bg-gray-700 text-white rounded-full">
  <a href={`https://wa.me/${currentProduct.phoneNumber}`}><BsWhatsapp size={15} /></a>
  </button>

  <button className="p-1 bg-gray-700 text-white rounded-full">
 <a href={`tel:${currentProduct.phoneNumber}`}>    <IoMdCall size={15} /></a>
  </button>
  <button className="p-1 bg-gray-700 text-white rounded-full">
 <a href={`mailto:${currentProduct.email}`}>    <MdEmail size={15} /></a>
  </button>
</div>
            </div>
            </div>
          </div>
        )}
      </div>


      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}
      <br/><br/><br/>
    </div>
  );
}

export default RentHouse;
