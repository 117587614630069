import React from 'react';
import InsideHeader from './InsideHeader';
import AnotherHeader from './AnotherHeader';
import BackgroundHouse from './BackgroundHouse';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FaHouseUser, FaSearch, FaMapMarkerAlt } from 'react-icons/fa'; // Importing additional icons
import { MdOutlineEventAvailable } from 'react-icons/md';
import NewHeader from './NewHeader';


function AccommodationOut() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      
      <NewHeader/>
   
      <div className="relative min-h-screen">
        <BackgroundHouse />
        <div className="flex flex-col absolute inset-0 bg-opacity-70 items-center justify-center h-screen bg-gray-100">
          <h1 className="text-3xl font-extrabold text-white drop-shadow-lg mb-6 text-center">
            Welcome to Our <span className="text-gray-600">Accommodation Rental Service</span>
          </h1>
          <p className="text-lg text-black mb-8 text-center max-w-lg">
            Discover affordable, safe, and convenient rental options for students and travelers. Whether you're looking for a cozy hostel or luxurious apartments, we've got you covered!
          </p>
          <div className="flex flex-wrap space-x-4">
            <Link to="/Hostel">
              <button className="flex items-center px-6 py-3 bg-gray-600 text-white text-lg font-semibold rounded-lg shadow-lg hover:bg-green-700 transition-transform transform hover:scale-105">
                <FaSearch className="mr-2" /> Find a Hostel
              </button>
            </Link>
          
          </div>
          <div className="flex text-black items-center justify-center flex-wrap mt-8 space-x-6">
            <div className="flex items-center space-x-2">
              <MdOutlineEventAvailable className="text-2xl" />
              <p>Exclusive Deals Available</p>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccommodationOut;
