import React, { useState, useEffect} from 'react';
import axios from 'axios';




import InsideHeader from './InsideHeader';

import { Link, useLocation } from 'react-router-dom';
import { useContext} from 'react';
import { UserContext } from './UserContext';
import {FaStore} from 'react-icons/fa';
import AnotherHeader from './AnotherHeader';
import { IoMdPerson } from "react-icons/io";
import { FaHouseUser } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import {IoMdCall } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { IoIosArrowBack } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import { FaFilter } from "react-icons/fa6";
import { FaCheckCircle } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaLocationDot } from "react-icons/fa6";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Market = ()  => {

  const { user, setUser} = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [currentProductOwner, setCurrentProductOwner] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUserNavOpen, setIsUserNavOpen] = useState(false); // State to handle user icon dropdown toggle
  const [selectedCategory, setSelectedCategory] = useState(''); // State for selected category
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate(); // Initialize useNavigate
  const [error, setError] = useState('');
  const [isNavOpen, setIsNavOpen] = useState(false); // State to handle sidebar toggle
  const [isVisible, setIsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for pagination
  const [productsPerPage] = useState(10); // Number of products per page

  const handleClose = () => {
    setIsVisible(false);
  };








  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/Login'); // Redirect to login if no token
          return;
        }

        const response = await axios.get('https://mybackend-new.onrender.com/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch user data');
        navigate('/Login'); // Redirect to login if fetching user data fails
      }
    };

    fetchUserData();
  }, [setUser, navigate]);







  useEffect(() => {
    fetchProducts();
  }, [searchTerm, selectedCategory]); // Refetch products whenever searchTerm or selectedCategory changes

  const fetchProducts = async () => {

    const token = localStorage.getItem('token');
   
    console.log(selectedCategory);
    try {

      if (!token) {
        navigate('/Login'); // Redirect to login if no token
        return;
      }
  


      const res = await axios.get(`https://mybackend-new.onrender.com/products`, {
        params: {
          search: searchTerm,
          category: selectedCategory, // Pass selected category to the API
        },
      });
 
      const shuffledProducts = shuffleArray(res.data); // Shuffle products randomly
      setProducts(shuffledProducts);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      navigate('/Login'); // Redirect to login if fetching user data fails
      setLoading(false);
    }
  };

 
  // Fisher-Yates shuffle algorithm to randomize the order of products
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };
  

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };


    
  const handleMessageClick = (ownerId) => {
    const isConfirmed = window.confirm('Would you like to be redirected to WhatsApp chat?');

    if(isConfirmed){    
    setCurrentProductOwner(ownerId);
    setShowMessageModal(true);
    };

  };

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://mybackend-new.onrender.com/messages`, {
        ownerId: currentProductOwner,
        content: messageContent
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setShowMessageModal(false);
      setMessageContent('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };


  const toggleDescription = (productId) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [productId]: !prevState[productId]
    }));
  };

  const getTimeDifference = (timestamp) => {
    const now = new Date();
    const createdAt = new Date(timestamp);
    const differenceInSeconds = Math.floor((now - createdAt) / 1000);

    const days = Math.floor(differenceInSeconds / (3600 * 24));
    const hours = Math.floor((differenceInSeconds % (3600 * 24)) / 3600);
    const min = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    if (days > 0) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
    } else if (min > 0) {
      return `${min} min${min !== 1 ? 's' : ''} ago`;
    } else {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
  };

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.owner_username.toLowerCase().includes(searchTerm.toLowerCase())
  );



  // Get the current products for the page
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);



  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const userNavItems = [
    { id: 'clothes', label: 'Clothes' },
    { id: 'phones_laptops', label: 'Phones & Laptops' },
    { id: 'electronics', label: 'Electronics' },
    { id: 'drug_store', label: 'Drug Store' },
    { id: 'lotion_saloon', label: 'Lotion and Saloon' },
    { id: 'farming', label: 'Farming' },
    { id: 'cars_automobile', label: 'Cars & Automobile' },
    { id: 'sports', label: 'Sports' },
    { id: 'infrastructures', label: 'Infrastructures' },
    { id: 'food', label: 'Food' },
    { id: 'books_stationary', label: 'Books and Stationary' },
    { id: 'other', label: 'Other' },
  ];







  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsUserNavOpen(false); // Close dropdown on category selection
  };
  
  const toggleUserNav = () => {
    setIsUserNavOpen(!isUserNavOpen); // Toggle user dropdown visibility
  };
  


  const showNotice = (message) => {

      alert(message);
    // Set a timeout to clear the notice after 3 seconds
    setTimeout(() => {
      return;
    }, 3000); // 3 seconds
  };




  const showSuccess = () => {

    setIsVisible(true);
  // Set a timeout to clear the notice after 3 seconds
  setTimeout(() => {
    return;
  }, 3000); // 3 seconds
};






  const handleOrderClick = async (productId, owner, email) => {
    if (user) {




      const buyerId = user.id;

      console.log(email);
  
      // Check if product is already in the cart
      try {
        const token = localStorage.getItem('token');
        const checkCartResponse = await axios.get('https://mybackend-new.onrender.com/check-cart', {
          params: { product_id: productId, buyer_id: buyerId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (checkCartResponse.data.exists) {
          showNotice('Product is already in your cart. Cannot place order again.');
          return;
        }
  
        const isConfirmed = window.confirm('Are you sure you want to order the product?');
        if (isConfirmed) {


      if (isSubmitting) return;
      setIsSubmitting(true);
          if (buyerId !== owner) {
            try {
              const orderResponse = await axios.post(
                'https://mybackend-new.onrender.com/orders',
                {
                  productId,
                  Owner: owner,
                  buyerId,
                  email
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
   
              );
              
            
              if (orderResponse.status === 200) {
                setIsVisible(true);
              
              }
            } catch (error) {
              console.error('Error placing order:', error);
            }
            finally {
              setIsSubmitting(false); // Reset the loading state
            }
          } else {
            showNotice('Oops! Sorry, you cannot order your own Product');
          }
        }
      } catch (error) {
        console.error('Error checking cart:', error);
      }
    }
  };
  

  
  const toggleImage = (image) => {
    console.log("Image clicked");
    setSelectedImage(selectedImage === image ? null : image);

  };


  const toggleNav = () => {
    setIsNavOpen(!isNavOpen); // Toggle sidebar visibility
  };

  const togglePersonModal = (product) => {
    setCurrentProduct(product);
    setShowPersonModal(!showPersonModal);
  };

  


  return (
    <div  >
      
      <div  className="relative min-h-screen flex">
        <AnotherHeader/>
        <InsideHeader  />
        
          

          {loading ? (<div className='text-center justify-center mt-28' >...loading Products</div>
            ):

             (
                <div className="flex-1 p-0 rounded-6xl pt-14">
                            <div className="bg-white">
                              <div className="flex">
                                <div className="mb-4 flex w-full mr-4">
                                  <span className='mr-2 mt-1 ml-2'>Search</span>
                                  <input
                                    type="text"
                                    placeholder="Search by name, description, or owner"
                                    value={searchTerm}
                                    onChange={handleSearchChange} // Capture input changes
                                    className="p-1 border border-gray-300 w-full rounded-xl"
                                  />
                                </div>
                                <div className='relative ml-auto mt-1 mr-4 text-gray-600'>
                                  <button onClick={toggleUserNav} className="focus:outline-none">
                                    <FaFilter size={23} />
                                  </button>
                                </div>
                                {isUserNavOpen && (
                                  <div className="absolute right-0 mt-6 w-48 bg-white shadow-lg rounded-lg py-2 z-50">
                                    <span className='ml-2 text-sm font-semibold'>Select Category</span>
                            
                                    {userNavItems.map(item => (
                                      <button
                                        key={item.id}
                                        onClick={() => handleCategorySelect(item.id)} // Update category on click
                                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                      >
                                        {item.label}
                                      </button>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <div className="grid grid-cols-2 md:grid-cols-5 gap-1">
                                {currentProducts.map(product => (
                                  <div key={product.id} className="mb-2">
                                    <img
                                      src={product.picture}
                                      alt={product.name}
                                      onClick={() => togglePersonModal(product)}
                                      className="w-full h-44 object-cover mb-2 "
                                    />
                                    <div className="flex pl-1 pr-1">
                                      <p className="text-sm text-center">{product.name}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="flex justify-center items-center mt-4">
                                <button
                                  onClick={() => paginate(currentPage - 1)}
                                  disabled={currentPage === 1}
                                  className="p-2 bg-gray-300 rounded-lg disabled:opacity-50 mr-2"
                                >
                                  <MdKeyboardDoubleArrowLeft />
                                </button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <button
                                  onClick={() => paginate(currentPage + 1)}
                                  disabled={currentPage === totalPages}
                                  className="p-2 bg-gray-300 rounded-lg disabled:opacity-50 ml-2"
                                >
                                  <MdKeyboardDoubleArrowRight />
                                </button>
                              </div>
                            </div>
                          </div>
         )}
      
     
      </div>
      {showPersonModal && currentProduct && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className=" border bg-white border-gray-300  w-full max-w-lg h-full overflow-auto">
      
    <button className='mt-2'
          onClick={togglePersonModal}>
        <IoIosArrowBack size={24} />
      </button>

      <hr className="border-t-2 border-gray-100 mb-1 mt-2" />
      {/* Full Picture at the Top */}
      <div className='w-full'>
        {currentProduct.picture && (
          <img
            src={`${currentProduct.picture}`}
            alt={currentProduct.name}
            onClick={() => toggleImage(`${currentProduct.picture}`)}
            className="w-full h-auto object-cover rounded-lg border-2 border-gray"
          />
        )}
      </div>

  {isVisible && (
   <div className="fixed inset-0 flex items-center justify-center z-60">
    <div className="p-5 bg-white border  rounded-lg flex flex-col items-center w-80">
      <p className='text-black font-semibold text-md text-center ' >Congratulations!</p>
      <p className="text-black text-sm mb-2 text-center">The product has been added in your cart.</p>
      <FaCheckCircle className="text-green-600 text-5xl mb-4" />
      <button
        className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-green-600"
        onClick={handleClose}
      >
        OK
      </button>
    </div>
    </div> 
  )}



      {/* Other Content Below the Picture */}
      <div className="p-4">
      <p className='text-lg font-bold'> {currentProduct.name}</p>
        <p className='text-2xs  mb-2 text-gray-700 text-sm mb-2  max-h-24 overflow-hidden'> {currentProduct.description}</p>
        <p className='text-2xs text-green-500'>MK{Math.round(currentProduct.price).toLocaleString()}</p>
        <p className='text-2xs'><span className='font-bold' >Status: </span>{currentProduct.status}</p>
        <div className="flex items-center justify-center mt-4">
          <ImProfile size={20} className="mr-1" />
          <h2 className="text-md font-bold text-center">Our Business Profile</h2>
        </div>
        <hr className="border-t-2 border-gray-300 mb-4 mt-2" />  
        <p className='mb-2 text-gray-700 text-center text-sm mb-2  mt-2 max-h-24 '> {currentProduct.userDescription}</p>
        <hr className="border-t-2 border-gray-300 mb-4 mt-2" />
        <p className='text-2xs'><span className='font-bold' >Seller: </span>{currentProduct.owner_username}</p>
        <p className='text-2xs flex'><span className='font-bold mt-1 mr-2' ><FaLocationDot/> </span>{currentProduct.location}</p>
        <div className="flex space-x-4 mt-2">
          <button
            onClick={togglePersonModal}
            className="p-2 bg-red-800 text-white rounded-xl flex items-center"
          >
            <MdCancel className="mr-1" />
            Close
          </button>

         <button
           onClick={() => handleOrderClick(currentProduct.id,currentProduct.owner_id, currentProduct.email)}
           type="submit"
           disabled={isSubmitting}
           className={`className="text-gray-800 text-xs font-semibold mr p-2 border-none bg-gray-300 rounded-lg cursor-pointer"${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-gray-600'}`}>
           {isSubmitting  ? 'Shopping' : 'Add to Cart' }
        </button>
                   
        </div>
      </div>
    </div>
  </div>
)}


{selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <img
              src={selectedImage}
              alt="Product"
              className="max-w-full max-h-full rounded-xl"
              onClick={() => setSelectedImage(null)}
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-0 right-0 p-2 text-white bg-black rounded-full"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <br/><br/>
    </div>
 
  );
}

export default Market;
